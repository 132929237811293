<template>
  <div>
    <!-- 顶部 -->
    <div class="agreement-header-wrap">
      <!-- PC -->
      <div class="pc-header">
        <div class="main">
          <a href="" target="_blank" class="logo">科汇云</a>
        </div>
      </div>
      <!-- H5 -->
      <!-- <div class="m-header">
        <i class="goback" onclick="javascript:history.back()"></i>
        <div class="title">科汇云会员服务协议</div>
      </div>-->
      <div class="agreement-blank"></div>
    </div>
    <!-- 内容 -->
    <div class="agreement-con">
      <h2>科汇云交易服务协议</h2>
      <div>尊敬的客户：</div>
      <div>
        科汇云交易服务是由科汇云信息技术（重庆）有限公司（以下简称本公司）向科汇云用户提供的技术交易服务系统（以下简称本系统），包括在线交易通道、线下鉴证网络、交易过程服务等。本协议由您和本公司签订。
      </div>
      <h3><strong>一、声明与承诺</strong></h3>
      <p>
        <strong
          >（一）本协议已对与您的权益有关或可能具有重大关系的条款，及对本公司具有或可能具有免责或限制责任的条款用粗体字予以标注，请您注意。</strong
        >您确认，在您注册成为科汇云用户已接受科汇云交易服务，或您以其他本公司允许的方式实际使用科汇云交易服务前，您已充分阅读、理解并接受本协议的全部内容，一旦您使用科汇云交易服务，即表示您同意遵循本协议之所有约定。
      </p>
      <p>
      （二）您同意，本公司有权随时对本协议内容进行单方面的变更，并以在本网站公告的方式予以公布，无需另行单独通知您；若您在本协议内容公告变更后继续使用科汇云交易服务的，表示您已充分阅读、理解并接受修改后的协议内容，也将遵循修改后的协议内容使用科汇云交易服务；若您不同意修改后的协议内容，您应停止使用科汇云交易服务。
      </p>
      <p>
        （三）您保证，在您同意接受本协议并注册成为科汇云用户时，<strong>您已经年满18周岁</strong>，或者您是在中国大陆地区合法开展经营活动或其他业务的法人或其他组织；本协议内容不受您所属国家或地区法律的排斥。不具备前述条件的，您应立即停止使用科汇云交易服务。<strong
          >您在使用科汇云交易服务时，应自行判断交易对方是否是完全民事行为能力人并自行决定是否与对方进行交易或付款给对方等，且您应自行承担与此相关的所有风险。</strong
        >
      </p>
      <p>
        (四)&nbsp;<strong>您同意，您在科汇云上发生的所有交易，您不可撤销地授权由科汇云按照其制定的交易规则进行处理；同时，您不可撤销地授权科汇云有权按照您与交易对方进行纠纷协调的结果将争议款项的全部或部分支付给交易一方或双方；科汇云按照交易规则进行资金的冻结、扣划完全来自于您的授权，科汇云对因此给您造成的任何损失均不承担责任。</strong>但您确认，您使用科汇云交易服务时，您仍应完全遵守本协议及科汇云制定的各项规则及页面提示等。
      </p>
      <h3><strong>二、科汇云交易服务内容概要</strong></h3>
      <p>
        科汇云交易服务：即本公司向交易会员提供的在线技术交易服务系统，其中包括：
      </p>
      <p>
        （一）在线交易通道：包括合同签约与订单管理、担保支付管理、资料交付管理等系统功能。您可以通过该通道实现技术交易的在线全流程管理，包括在线签订技术交易合同，根据交易关键性节点制定合同订单，并进行款项支付和技术资料交付，以及合同变更、交易纠纷协调等操作。
      </p>
      <p>
        （二）线下鉴证网络：包括项目质量鉴证机构和交易证据公证机构。您可以通过该服务，指定权威验收机构对技术项目建设成果进行验收；获取实时存证的交易电子数据进行公证。 
      </p>
      <p>
        （三）交易过程服务：包括专业客服服务、专业机构服务和技术经纪服务等，您可以通过该服务，解决技术交易过程中的各类个性化难题，包括但不限于系统操作引导、交易纠纷协调、法律咨询、知识产权咨询、专利情报分析、技术中介服务等。 
      </p>
      <h3><strong>三、科汇云账户</strong></h3>
      <p>（一） 注册</p>
      <p>您须在科汇云网站直接注册并取得本公司提供给您的科汇云账户。您同意：</p>
      <p>
        1、按照科汇云要求准确提供并在取得该账户后及时更新您正确、最新及完整的身份信息及相关资料。<strong>若本公司有合理理由怀疑您提供的身份信息及相关资料错误、不实、过时或不完整的，本公司有权暂停或终止向您提供部分或全部科汇云交易服务直至您提供足以证明你正确、最新及完整的身份信息的相关资料。本公司对此不承担任何责任，您将承担因此产生的任何直接或间接支出。</strong>若因国家法律法规、部门规章或监管机构的要求，本公司需要您补充提供任何相关资料时，如您不能及时配合提供，本公司有权暂停或终止向您提供部分或全部科汇云交易服务。
      </p>
      <p>
        2、<strong>您应当准确提供并及时更新您提供的电子邮件地址、联系电话、联系地址、邮政编码等联系方式，以便本公司与您进行及时、有效联系。您应完全独自承担因通过这些联系方式无法与您取得联系而导致的您在使用科汇云交易服务过程中遭受的任何损失或增加任何费用等不利后果。</strong>您理解并同意，您有义务保持您提供的联系方式的有效性，如有变更需要更新的，您应按本公司的要求进行操作。
      </p>
      <p>
        3、<strong
          >因您未及时更新资料（包括但不限于身份证、户口本、护照等证件或其他身份证明文件、联系方式、与科汇云账户绑定的邮箱、手机号码等），导致科汇云交易服务无法提供或提供时发生任何错误，您不得将此作为取消交易、拒绝付款的理由，您将承担因此产生的一切后果，本公司不承担任何责任。</strong
        >
      </p>
      <p>
        &nbsp;4、您应对您的科汇云账户负责，只有您本人可以使用您的科汇云账户。<strong
          >在您决定不再使用该账户时，您应确保处理完成该账户下所对应的所有支付、收取事项，并向本公司申请注销该账户。</strong
        >
      </p>
      <p>
        <strong
          >您同意，若您丧失全部或部分民事权利能力或民事行为能力，本公司有权根据有效法律文书（包括但不限于生效的法院判决、生效的遗嘱等）处置与您的科汇云账户相关的款项。</strong
        >
      </p>
      <!-- <p>（二）技术市场联盟相关</p> -->
      <!-- <p>
        1、技术市场联盟（以下简称联盟）是与科汇云建立战略合作关系并嵌入科汇云技术交易服务系统的技术交易网络平台的总称，目前联盟成员包括科汇云（www.1633.com）、淘科技-海宁网上技术交易平台（www.hn51js.gov.cn）、厦门网络大学科技园（www.xmdxy.net.cn）、泉州（晋江）专利技术展示交易中心（www.jjky.gov.cn）、思明区网上知识产权交易中心等。您理解并同意，科汇云有权根据业务扩展情况以公告形式告知您新的联盟成员。
      </p> -->
      <!-- <p>
        2、为实现科汇云与联盟平台之间的信息互通，科汇云提供科汇云账户与联盟账户的共通功能，您可以通过您的科汇云账户直接登录任一联盟平台，或用联盟账户直接登录科汇云。<strong
          >您在此明确授权，在您完成科汇云账户注册后，科汇云有权从技术市场联盟获取您的相关信息，包括但不限于您在联盟平台发布的技术项目信息、技术需求信息和个人身份信息等。</strong
        >
      </p> -->
      <p>（二） 账户安全</p>
      <p>
        科汇云账户对应的身份识别信息包括登录密码、交易密码和科易盾（科汇云颁发的安全数字证书）数字签名，登录密码用于账户登录使用，交易密码用于交易重要环节使用，如果获得科易盾还需通过科易盾进行数字签名验证。以上三个身份识别信息统称密码。您将对使用该账户及密码进行的一切操作及言论负完全的责任，您同意：
      </p>
      <p>
        1、本公司通过您的科汇云账户名和密码识别您的指示，<strong
          >您应当妥善保管您的科汇云账户名和密码及身份信息，对于因密码泄露、身份信息泄露所致的损失，由您自行承担。您保证不向其他任何人泄露您的科汇云账户密码以及身份信息，亦不使用其他任何人的科汇云账户名及密码。本公司亦可能通过科汇云交易服务应用您使用的其他产品或设备识别您的指示，您应当妥善保管处于您或应当处于您掌控下的这些产品或设备，对于这些产品或设备遗失所致的任何损失，由您自行承担。</strong
        >
      </p>
      <p>
        2、您同意，（a）如您发现有他人冒用或盗用您的科汇云账户名及密码或任何其他未经合法授权之情形时，应立即以有效的书面方式通知本公司，要求本公司暂停相关服务。您授权本公司将前述情况同步给联盟平台，以保障您的合法权益；（b）<strong
          >确保您在持续登录科汇云及任一联盟平台时段结束时，以正确步骤离开网站。本公司不能也不会对因您未能遵守本款约定而发生的任何损失、损毁及其他不利后果负责。您理解本公司对您的请求采取行动需要合理期限，在此之前，本公司对已执行的指令及(或)所导致的您的损失不承担任何责任。</strong
        >
      </p>
      <p>
        3、除非有法律规定或司法裁判，且征得本公司同意，否则您的科汇云账户名及密码不得以任何方式转让、赠与或继承。
      </p>
      <h3><strong>四、科汇云交易服务使用规则</strong></h3>
      <p>
        为有效保障您使用科汇云交易服务时的合法权益，您理解并同意接受以下规则：
      </p>
      <p>
        <strong
          >（一）一旦您使用科汇云交易服务，您即授权本公司代理您在您及（或）您指定人符合指定条件或状态时，支付款项给您指定人，或收取您指定人支付给您的款项。</strong
        >
      </p>
      <p>（二）本公司通过以下三种方式接受来自您的指令：</p>
      <p>
        其一您通过您注册时作为该账户名称或者与该账户绑定的手机或其他专属于您的通讯工具（以下合称该手机）号码向本系统发送的信息（短信或电话等）回复；
      </p>
      <p>
        其二您通过您注册时作为该账户名称或者与该账户名称绑定的其他硬件、终端、软件、代号、编码、代码、其他账户名等有形体或无形体向本系统发送的信息（本方式所指有形体或无形体具备与该手机接受信息相同或类似的功能。
      </p>
      <!-- <p>
        其三，您通过您注册时作为该账户名称或者与该账户名称绑定的其他硬件、终端、软件、代号、编码、代码、其他账户名（如联盟账户）等有形体或无形体向本系统发送的信息（本方式所指有形体或无形体具备与该手机接受信息相同或类似的功能，以下第五条第
        （三）、（四）项和第六条第（三）项涉及该手机的条款同样适用于本方式）。
      </p> -->
      <p>
        无论您通过以上两种方式中的任一种向本公司发出指令，都不可撤回或撤销，且成为本公司代理您支付或收取款项或进行其他账户操作的唯一指令，视为您本人的指令，您应当自己对本公司忠实执行上述指令产生的任何结果承担责任。除本协议另有规定外，您与第三方发生交易纠纷时，本公司将经您确认同意的纠纷协调结果视为您的指令，将争议款项的全部或部分支付给交易一方或双方。 
      </p>
      <p>
        <strong
          >（三）您在使用科汇云交易服务过程中，本协议内容、网页上出现的关于交易操作的提示或本公司发送到该手机的信息（短信或电话等）内容是您使用科汇云交易服务的相关规则，您使用科汇云交易服务即表示您同意接受科汇云相关规则。</strong
        >您了解并同意本公司有权单方修改服务的相关规则，而无须征得您的同意，服务规则应以您使用服务时的页面提示（或发送到该手机的短信或电话等）为准，您同意并遵照服务规则是您使用科汇云交易服务的前提。 
      </p>
      <p>
        （四）本公司会以电子邮件（或发送到<strong>该手机</strong>的短信或电话等）方式通知您交易进展情况以及提示您进行下一步的操作，但本公司不保证您能够收到或者及时收到该邮件（或发送到该手机的短信或电话等），且不对此承担任何后果，因此，在交易过程中您应当及时登录到科汇云或联盟平台查看和进行交易操作。因您没有及时查看和对交易状态进行修改或确认或未能提交相关申请而导致的任何纠纷或损失，本公司不负任何责任。
      </p>
      <p>
        （五）科汇云实行实名制交易，若您未通过实名认证，您将无法使用科汇云的相关功能，包括签约、支付、交付等核心系统。请确保您根据实名认证要求提供真实、准确、最新、完整的身份证明材料，并依据认证要求完成实名认证操作流程。<strong
          >若本公司有合理理由怀疑您提供的身份认证资料错误、不实、过时或不完整的，有权立即终止您的实名认证状态，本公司不承担任何责任，由此造成的损失由您自行承担。</strong
        >
      </p>
       <p>
        科汇云建议您：一旦您的单项合同交易总额大于或等于人民币1万元，宜申请成为科汇云交易会员，科汇云有权收取相应的交易会员费用。如果您的单项合同交易总额低于人民币1万元，您可以在通过实名认证前提下进行签约、支付、交付等操作完成交易，但科汇云对因采用非交易会员交易而产生的法律效力不足、数据泄密等潜在风险不承担任何责任，应由您与交易对方自行处理，科汇云可以在合法程序要求下提供保留在服务器上的相关证据作为司法协助。
      </p>
      <p>
        （六）科汇云建议您，根据技术交易过程中关键性节点，包括但不限于技术资料的交付、知识产权的转移、工艺安装、项目验收等，对技术交易流程进行合理分解并制定合同订单，订单将作为合同的组成部分，与合同具有同等法律效力。合同条款和订单细则由您与交易对方最终协商决定，对因您的合同条款和订单细则制定不当问题产生的交易纠纷或由此造成的损失，本公司不承担任何责任。
      </p>
      <!-- <p>
        <strong
          >科汇云建议您：一旦您的单项合同交易总额大于或等于人民币1万元，宜申请成为科汇云交易会员，科汇云有权收取相应的交易会员费用。如果您的单项合同交易总额低于人民币1万元，您可以在通过实名认证前提下进行签约、支付、交付等操作完成交易，但科汇云对因采用非交易会员交易而产生的法律效力不足、数据泄密等潜在风险不承担任何责任，应由您与交易对方自行处理，科汇云可以在合法程序要求下提供保留在服务器上的相关证据作为司法协助。</strong
        >
      </p> -->
      <p>
        
        （七）科汇云建议您，通过科汇云提供的安全交付通道（即有交易会员权限的交付通道）交付您的技术资料和其它重要交易证据，可有效保障您的资料交付不被第三方截获、接收方不可抵赖和实现交付过程即时存证。因您未通过科汇云安全交付通道交付您的重要交易资料而造成证据缺失风险、或因您仅采用普通交付方式（即非交易会员交付通道）而造成的证据法律效力不足、数据泄密风险，本公司概不承担任何责任。
      </p>
      <p>
         （八）科汇云建议您，应在合同和订单中明确约定技术项目验收标准，尽可能对验收标准进行量化描述；邀请权威项目验收机构进行项目验收并出具书面验收报告；若由技术购买方自行验收也应出具书面验收证明。因您与交易对方对项目验收标准约定不准确、未邀请权威验收机构验收或验收方未出具验收证明等问题造成的交易纠纷或由此造成的损失，本公司概不承担任何责任。 
      </p>
      <p>
        （九）科汇云建议您，若与交易对方产生交易纠纷，尽可能通过科汇云技术交易纠纷协调专用通道进行磋商，以保存完整的纠纷协调证据。因未通过科汇云协调通道解决交易纠纷而造成的证据缺失问题，本公司不承担任何责任。
      </p>
      <p>
       （十）您同意，为了您的科汇云账户及其内资金的安全，根据本协议的约定、法律法规及法律文书的规定、政府依行政职权的要求、本公司依据自行判断认为的可能对您的科汇云账户产生风险的情况、您在交易过程中产生纠纷并处于自主协调、科汇云专业客服协调或仲裁机构、司法机关介入调解期间，本公司有权对您名下的全部或部分担保支付资金进行冻结，即进行暂时关闭这些账户部分或全部使用权限的操作。冻结的逆过程为解冻，即本公司对被冻结的相关资金结束冻结。当冻结发生时，如您申请解冻，本公司有权依照自行判断根据本项规定前述的冻结原因来决定是否允许全部或部分解冻，您应充分理解您的解冻申请并不必然被允许，且申请解冻时您应当配合本公司核实您的身份的有关要求，提供包括但不限于身份信息、身份证、护照、其他有效的身份证明文件及本公司要求的其他信息或文件。
      </p>
      <p>
         （十一）您同意，本公司有权按照包括但不限于公安机关、检察机关、法院、海关、税务机关等司法机关、行政机关、军事机关的要求对您的科汇云账户及担保资金等进行查询、冻结或扣划。  
      </p>
      <p>
        （十二）您同意，基于运行和交易安全的需要，本公司可以暂时停止提供或者限制科汇云交易服务部分功能，或提供新的功能，在任何功能减少、增加或者变化时，只要您仍然使用科汇云交易服务，表示您仍然同意本协议或者变更后的协议。
      </p>
      <p>
       （十三）本公司并非银行或其它金融机构，科汇云交易服务也非金融业务，本协议项下的资金移转均通过银行和第三方担保账户来实现，你理解并同意您的资金于流转途中的合理时间。
      </p>
      <p>
       （十四）您确认并同意，您应自行承担您使用科汇云交易服务期间由本公司保管或代收或代付款项的货币贬值风险，并且本公司无须就此等款项向您支付任何孳息或其他对价。
      </p>
      <p>
       （十五）若您希望成为科汇云签约入驻服务机构或技术经纪人（机构），请您注册成为科汇云会员并遵守本协议所有规则，并与本公司签订入驻合作协议。技术经纪协议内容另见《科汇云技术经纪签约入驻协议》，服务机构以本公司和您签署的合作协议书为准。
      </p>
      <p>
       （十六）交易风险
      </p>
      
      <p>
       1、在使用科汇云交易服务时，若您或您的交易对方未遵从科汇云交易服务条款或网站说明、交易页面中之操作提示、规则），则本公司有权拒绝为您与交易对方提供相关服务，且本公司不承担损害赔偿责任。若发生上述状况，而相关款项已汇入您的银行账户，您同意本公司有向您事后索回之权利，因您的原因导致本公司事后追索的，您应当承担本公司合理的追索费用。
      </p>
      <p>
        2、因您的过错导致的任何损失由您自行承担，该过错包括但不限于：不按照交易提示操作，未及时进行交易操作，遗忘或泄漏密码，密码被他人破解，您使用的计算机被他人侵入。 
      </p>
      <p>（十七）我们可能发送的信息</p>
      <p>
        您理解并同意，科汇云可通过网页公告、电子邮件、站内信、短信、电话、系统信息以及即时通信等以上一种或多种方式向您发送通知、告知、展示广告及其他商业或非商业性质的信息，具体形式、频次及内容等以实际内容为准。 
      </p>
      <p>
        科汇云依照仅依法履行相应的广告及推广义务，并不对广告内容或推广信息的真实性负责。因此，您应当自行判断相关广告或推广信息的真实性和可靠性，如因该广告或推广信息进行的购买、交易或因前述广告或推广信息遭受的损害或损失应由您自行承担，科汇云不承担责任。 
      </p>
      <p>
        我们将在必要时（如系统维护暂停某服务、维修完毕恢复某服务等）向您发出与服务有关的通知或公告，届时如给您造成不便或打扰，烦请谅解。 
      </p>
      <p>（十八）您不得将科汇云交易服务用于非本公司许可的其他用途。</p>
      <h3><strong>五、科汇云交易服务费用条款</strong></h3>
      <p>
        （一）在您使用科汇云交易服务时，本公司有权依照科汇云交易服务收费条款向您收取服务费用。本公司拥有制订及调整服务费之权利，具体服务费用参见您使用科汇云交易服务时网站上所列之收费方式公告或您与本公司达成的其他书面协议。 
      </p>
      <p>
        （二）您认可并同意，您选择科汇云收费项目完全出于您的自愿，科汇云不会以任何强迫方式要求您接受收费服务。
      </p>
      <p>专业服务收费：科汇云提供的专业服务内容包括专业客服服务、专业机构服务和技术经纪服务。其中，专业客服服务属于交易会员服务权限；专业机构服务和技术经纪服务由科汇云整合的专业机构提供，属于增值服务内容，按单项收费。收费标准由您与您选择的服务机构或技术经纪人根据具体服务内容自行磋商决定。<strong>如果您选择科汇云签约技术经纪人进行服务，您同意，本公司有权自您委托本公司代管、代收或代付的款项中直接代扣经纪佣金。</strong></p>
      <p>

        （三）科汇云交易服务所涉及到的任何款项只以人民币计结，不提供任何其他形式的结售汇业务。
      </p>
      
     
      <p>
        （四）科汇云交易服务所涉及到的任何款项只以人民币计结，不提供任何其他形式的结售汇业务。
      </p>
      <h3><strong>六、科汇云交易服务使用限制规则</strong></h3>
      <p>
        （一）您在使用科汇云交易服务时应遵守中华人民共和国相关法律法规、您所在国家或地区之法令及相关国际惯例，不将科汇云交易服务用于任何非法或有害公序良俗的目的（包括用于禁止或限制交易内容的交易），也不以任何非法方式使用科汇云交易服务。
      </p>
      <p>
        （二）您不得利用科汇云交易服务从事侵害他人合法权益之行为，否则本公司有权拒绝提供科汇云交易服务，且您应承担所有相关法律责任。若因此导致本公司或本公司雇员受损的，您应承担相应的赔偿责任。上述行为包括但不限于：
      </p>
      <p>
        1、侵害他人名誉权、隐私权、商业秘密、商标权、著作权、专利权等合法权益。
      </p>
      <p>2、违反依法定或约定之保密义务。</p>
      <p>
        3、冒用他人名义或超越代理权以他人名义使用科汇云交易服务。
      </p>
      <p>
        4、从事不法交易行为，如洗钱、恐怖融资、贩卖枪支、毒品、禁药、盗版软件、黄色淫秽物品、其他本公司认为不得使用科汇云交易服务进行交易的内容等。
      </p>
      <p>5、提供赌博资讯或以任何方式引诱他人参与赌博。</p>
      <p>
        6、非法使用他人银行账户（包括信用卡账户）或无效银行账号（包括信用卡账户）交易。
      </p>
      <p>7、进行与您或交易对方宣称的交易内容不符的交易，或不真实的交易。</p>
      <p>8、从事任何可能含有电脑病毒或是可能侵害科汇云交易服务的交易行为。</p>
      <p>9、其他本公司有正当理由认为不适当之行为。</p>
      <p>
        （三）您理解并同意，本公司不对因下述任一情况导致的任何损害赔偿承担责任，包括但不限于利润、商誉、使用、数据等方面的损失或其他无形损失的损害赔偿
          (无论本公司是否已被告知该等损害赔偿的可能性)：
      </p>
      <p>
        1、本公司基于单方面判断，在发现或有合理理由怀疑您存在异常交易或有疑义或有违反法律规定或本协议约定之虞时，有权不经通知先行暂停或终止您名下全部或部分科汇云账户的使用（包括但不限于对这些账户名下的款项和在途交易采取取消交易、调账等限制措施），并拒绝您使用科汇云交易服务之部分或全部功能。
      </p>
      <p>
        2、您理解并同意：在您使用本协议项下或您与科汇云另行签署协议中的相关产品或服务，本公司有权基于单方面判断认为您的使用行为存在异常时，包括但不限于您收入或支付的款项金额以及/或操作频次不同于往常或者相关业务模式存在风险，或您使用科汇云的行为涉嫌违反国家相关法律法规、部门规章等，可以冻结您名下全部或部分担保款项。如果本公司冻结款项或暂停执行您的指令，本公司将会采取电话或邮件或者短信等方式通知您，但本公司不保证您能够收到或者及时收到该邮件（或发送到该手机的短信或电话等），且不对此承担任何后果。在本公司认为该等异常已经得到合理解释或有效证据支持或未违反国家相关法律法规及部门规章的情况下，最晚将于冻结款项或暂停执行指令之日起的30个日历天内解除冻结或恢复执行指令。但本公司有进一步理由相信该等异常仍可能对您或其他用户或本公司造成损失的情形除外，包括但不限于1）收到针对该等异常的投诉；2）您已经实质性违反了本协议或另行签署的服务协议，且我们基于保护各方利益的需要必须继续冻结款项或暂停执行指令。
      </p>
      <p>
        3、本公司有权了解您使用科汇云交易服务的真实交易背景及目的，您应如实提供本公司所需的真实、全面、准确的信息；如果本公司有合理理由怀疑您提供虚假交易信息的，本公司有权暂时或永久限制您所使用的科汇云交易服务的部分或全部功能。在必要时，本公司无需事先通知即可终止提供科汇云交易服务，并暂停、关闭或删除您名下全部或部分科汇云账户及这些账户中所有相关资料及档案，并将您滞留在这些账户的全部合法资金退回到您的银行账户。
      </p>
      <h3><strong>七、信息及隐私权保护</strong></h3>
      <p>
        一旦您同意本协议或使用科汇云交易服务，您即同意本公司按照以下条款来使用和披露您的个人信息。
      </p>
      <p>
       （一）您使用科汇云交易服务进行交易或使用该账户登录其他支持科汇云交易服务的网站时，您即授权本公司将您的个人信息和交易信息披露给与您交易的另一方或您登录的网站，该信息包括但不限于：您的真实姓名、联系方式、信用状况、科汇云账户。
      </p>
      <p>
        （二）科汇云登录名和密码在您注册或激活科汇云账户及服务时，我们会要求您设置科汇云登录名和密码来识别您的身份，并设置安全保护问题及其答案，以便在您丢失密码时用以确认您的身份。您仅可通过您设置的密码来使用该账户，如果您泄漏了密码，您可能会丢失您的个人识别信息，并可能导致对您不利的法律后果。该账户和密码因任何原因受到潜在或现实危险时，您应该立即和本公司取得联系，在本公司采取行动前，本公司对此不负任何责任。
      </p>
      <p>（三） 注册信息</p>
      <p>
      您注册时应提供您的真实姓名、地址、国籍、电话号码和电子邮件地址等国家法律法规要求的信息，您还可以选择来填写相关附加信息（包括但不限于您公司所在的省份和城市、时区和邮政编码、传真号码、个人主页和您的职务）。为有针对性地向您提供新的服务和机会，您了解并同意本公司及其关联公司或您登录的其他网站将通过您的联系方式（包括但不限于手机、电子邮件、等）向您发送相关通知及其他商业性电子信息。
      </p>
      <p>（四） 银行账户信息</p>
      <p>
        本公司所提供的服务将需要您提供您的银行账户信息，在您提供相应信息后，本公司将严格履行相关保密约定。但当您进行技术交易时需要提供银行账户信息给交易对方时除外。
      </p>
      <p><strong>（五） 交易行为信息</strong></p>
      <p>
        为了保障您使用科汇云交易服务的安全以及不断改进服务质量，本公司将记录并保存您登录和使用科汇云交易服务的相关信息，但本公司承诺不将此类信息提供给任何第三方（除双方另有约定或法律法规另有规定及本公司关联公司）。
      </p>
      <p>
       （六）为更为有效地向您提供服务，您同意，本公司有权将您注册及使用科汇云交易服务过程中所提供、形成的信息提供给本公司关联公司，该信息包括但不限于以上所列之注册信息、银行账户信息、交易行为信息。同时，为更安全有效地向您提供服务，根据法律法规的规定，或本公司需识别您的身份，或本公司认为您的账户存在风险时，本公司有权要求您提交身份信息（包括但不限于身份证、户口本、护照等证件或其他文件）。除本协议另有规定外，本公司不对外公开或向第三方提供您的信息，但以下情况除外：
      </p>
      
      <p> A、事先获得您的明确授权； ></p>
      <p>
         B、只有披露您的个人资料，才能提供您需要的产品和（或）服务； 
      </p>
      <p>C、按照本协议的要求进行的披露；</p>
      <p>D、根据法律法规的规定；</p>
      <p>E、按照法院、检察院或政府主管部门的要求；</p>
      <p>F、为维护本公司及其关联公司的合法权益；</p>
      <p>G、对您或您所代表公司的身份真实性进行验证。</p>
      <p>（七）Cookie的使用及设备软硬件配置信息的收集</p>
      <p>
        您了解并同意，本公司使用cookie来使本网站对用户更友好（它可以帮您省去为使用我们的服务而重复输入注册信息和跟踪您的浏览器的状态），同时，本公司站点会收集由您设备的软硬件配置信息生成的特征信息，用于标识您的设备，
      </p>
      <p>（八）外部链接</p>
      <p>
        本网站含有到其他网站的链接，但本公司对其他网站的隐私保护措施不负任何责任。本公司可能在任何需要的时候增加商业伙伴或共用品牌的网站。
      </p>
      <p>（九）信息安全</p>
      <p>
        本公司仅按现有技术提供相应的安全措施来使本公司掌握的信息不丢失，不被滥用和变造。这些安全措施包括向其它服务器备份数据和对用户密码加密。尽管有这些安全措施，但本公司不保证这些信息的绝对安全
      </p>
      <h3><strong>八、系统中断或故障</strong></h3>
      <p>
        系统因下列状况无法正常运作，使您无法使用各项服务时，本公司不承担损害赔偿责任，该状况包括但不限于：
      </p>
      <p>（一） 本公司在本网站公告之系统停机维护期间。</p>
      <p>（二） 电信设备出现故障不能进行数据传输的。</p>
      <p>
        （三）
        因台风、地震、海啸、洪水、停电、战争、疫情、恐怖袭击等不可抗力之因素，造成本公司系统障碍不能执行业务的。
      </p>
      <p>
        （四）
        由于黑客攻击、电信部门技术调整或故障、网站升级、银行方面的问题等原因而造成的服务中断或者延迟。
      </p>
      <h3><strong>九、责任范围及责任限制</strong></h3>
      <p>（一）本公司仅对本协议中列明的责任承担范围负责。</p>
      <p>
        （二）您明确并同意因交易所产生的任何风险应由您与交易对方承担，风险包括但不限于知识产权归属不清、交易标的内容不实以及技术开发的潜在风险等。
      </p>
      <p>
        （三）科汇云用户信息是由用户本人自行提供的，本公司依照设定程序进行审核，但无法保证该信息之完全准确、及时和完整，您应对您的判断承担全部责任。
      </p>
      <p>
       （四）本公司不对交易标的及科汇云交易服务提供任何形式的保证。
      </p>
      <p>
      （五）科汇云交易服务之合作单位，所提供之服务品质及内容由该合作单位自行负责。
      </p>
      <p>
        （六）您经由科汇云交易服务之使用下载或取得任何资料，应由您自行考量且自负风险，因资料之下载而导致您电脑系统之任何损坏或资料流失，您应负完全责任。
      </p>
      <p>
        (七）您自本公司及本公司工作人员或经由科汇云交易服务取得之建议和资讯，无论其为书面或口头形式，均不构成本公司对科汇云交易服务之保证。
      </p>
      <p>
      （八）在法律允许的情况下，本公司对于与本协议有关或由本协议引起的任何间接的、惩罚性的、特殊的、派生的损失（包括业务损失、收益损失、利润损失、使用数据或 其他经济利益的损失），不论是如何产生的，也不论是由对本协议的违约（包括违反保证）还是由侵权造成的，均不负有任何责任，即使事先已被告知此等损失的可能性。
      </p>
      <p>
        （九）除本协议另有规定外，在任何情况下，本公司对本协议所承担的违约赔偿责任总额不超过向您收取的当次服务费用总额。
      </p>
      <h3><strong>十、商标、知识产权的保护</strong></h3>
      <p>
        （一）
        本网站上所有内容，包括但不限于著作、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计，均由本公司或本公司关联企业依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。
      </p>
      <p>
        （二）
        非经本公司或本公司关联企业书面同意，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表本网站程序或内容。
      </p>
      <p>
        （三）
        尊重知识产权是您应尽的义务，如有违反并造成损失，，您应承担相应的损害赔偿责任。
      </p>
      <h3><strong>十一、法律适用与管辖</strong></h3>
      <p>
        本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，没有相关法律规定的。<strong
          >因本协议产生之争议，均应依照中华人民共和国法律予以处理并接受四川省达州市达川区人民法院提起诉讼。</strong
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "member",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
 <style scoped src="./agreement.css"></style>